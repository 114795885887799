/* misc */
$success-green: #8bc34a;
$blue-dark: #0064d2;
$blue: #2196f3;
$link-blue: #0064d2;
$paidy-red: #ff2751;
$white: #ffffff;

/* gray */
$paidy-gray-dark: #2a2e32; // main body text
$paidy-gray: #586065; // caption and labels
$paidy-gray-medium: #a1aab0; // placeholder
$paidy-gray-light: #cfd4d8; // input field underline
$paidy-gray-super-light: #eef0f2;
$paidy-gray-lightest: #f6f8fa; // frame header background
$paidy-header-color: #f6f1f1;
$paidy-neutral-gray: #8A8A8A;

$paidy-black: #1c1c1c;

$paidy-pink: #e5258c;
$paidy-charcoal: #404042;
$paidy-gray-light-bh: #e1e1e1;
$paidy-link-pink: #e52581;
$paidy-ash: #a3a3a3;
$paidy-off-white: #f0f0f0;
$paidy-beige: #f4f4f4;
