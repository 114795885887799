@import '../../styles/base';

.content{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 32px;
    padding: 24px 24px 0 24px;
    p {
        color: $paidy-gray-dark;
        margin: 0;
    }
}
.controls {
    padding: 0 24px;
    margin: 0 0 40px 0;
    text-align: center;
}

.cta-button{
    min-width: 280px;
    max-width: 312px;
}

.button {
    @media only screen and (min-width: $responsive-breaking-point) {
        max-width: 312px;
    }
}