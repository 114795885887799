@import '../../styles/base';

.container {
  @include rem(padding-top, 12px);
  position: relative;
}

.line-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line-indicator-container {
  display: flex;
  justify-content: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
  > img {
    width: 16px;
    height: 16px;
  }
}

.date-text {
  font-family: $font-family;
  font-style: normal;
  font-weight: 300;
  @include rem(font-size, 13px);
  @include rem(line-height, 16px);
  text-align: right;
  flex: 1;
  color: $paidy-charcoal;
  @include rem(padding-right, 12px);
}

.amount-text {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  @include rem(font-size, 13px);
  @include rem(line-height, 16px);
  text-align: left;
  flex: 1;
  color: $paidy-charcoal;
  @include rem(padding-left, 12px);
}

.expand-button {
  position: absolute;
  @include rem(height, 96px);
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 43.23%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include rem(padding-bottom, 16px);
  div {
    display: flex;
    justify-content: center;
    @include rem(margin-bottom, 15px);
  }
  .expand-button-text {
    font-family: $font-family;
    font-style: normal;
    font-weight: 300;
    @include rem(font-size, 13px);
    @include rem(line-height, 16px);
    text-align: center;
    color: $paidy-link-pink;
  }
}
