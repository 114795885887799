@import '../../styles/base';

.default-block {
  @include rem(padding-bottom, 16px);
  position: relative;

  button,
  :global(.btn) {
    @extend %btn-shadow;
  }
}

.memos {
  @include rem(padding, 12px 0 24px);
  width: 100%;
}

.memo {
  color: $paidy-gray;
  position: relative;
  font-weight: map-get($font-weight, normal);
  @include rem(font-size, 13px);
  @include rem(line-height, 20px);
  @include rem(padding-left, 16px);

  &::before {
    content: '※';
    position: absolute;
    left: 0;
    top: 0;
    @include rem(height, 20px);
    @include rem(line-height, 20px);
  }
}

.list-item {
  @include rem(margin-bottom, 16px);
}

.multi-pay {
  width: 100%;
  position: relative;
  margin: 0 auto;
  box-shadow: none;
  font-weight: map-get($font-weight, semi-bold);
  @include rem(padding, 0 24px);
  @include rem(height, 56px);
  @include rem(font-size, 16px);
  @include rem(line-height, 56px);

  > span {
    position: absolute;
    top: 0;

    &.number-of-installments {
      font-weight: 400;
      @include rem(left, 24px);
    }

    &.total-amount {
      @include rem(right, 24px);
    }
  }

  &.selected {
    background-color: #b40064;
  }

  &.unselected {
    background: #fff;
    color: $blue-dark;
    @include rem(border, 1px solid $blue);
  }
}

@media screen and (max-height: $responsive-breaking-point) {
  .memos {
    @include rem(margin, 16px auto #{$app-mobile-padding + 16px});
  }
}

// iPhone 5
@media screen and (device-aspect-ratio: 40/71) {
  .memos {
    @include rem(margin, 16px auto);
  }
}
