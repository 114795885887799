@import '../../styles/base';

.container {
  padding: 16px 24px;
  border: 2px solid #e1e1e1;
  border-radius: 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 32px;
  align-items: center;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 24px;
  }

  &.active {
    border-color: $paidy-pink;
  }
}

.description {
  font-size: 13px;
  grid-column-start: 1;
  grid-row-start: 2;
  color: $paidy-gray;
}

.check-container {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  grid-column-start: 2;
  margin: 8px;
  border: 2px solid $paidy-ash;

  &.checked {
    background-image: url('../../assets/icon-checked-circle.svg');
    background-size: cover;
    border: 0;
  }
}

.split-count-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.amount {
  font-size: 26px;
  margin-right: 4px;
}

.disabled.container {
  color: $paidy-gray-super-light;
  cursor: not-allowed;
  border: 1.5px solid $paidy-gray-super-light;
}

.disabled.container .description{
  color: $paidy-gray-super-light;
}

.disabled.container .check-container {
  border-color: $paidy-gray-super-light;
}