@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.title {
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 600;
}

.sub-title {
  font-size: 16px;
  color: $paidy-charcoal;
  font-weight: 600;
}

.ul{
  list-style-type: disc;
  margin: 0;
  padding: 0 0 0 25px;
  li{
    margin: 0 0 12px 0;
    padding-left: 8px;
    &::marker {
      color: $paidy-charcoal;
    }
  }
}

.mb24{
  margin-bottom: 24px;
}

.with-icon{
  display: flex;
  align-items: center;
}

.npay-description {
  font-size: 13px;
  margin-bottom: 20px;
}

.secondary-text {
  font-size: 13px;
  line-height: 16px;
  &.block{
    display: block;
  }
  &.gray{
    color: #A3A3A3;
  }
}

.exclusive {
  font-size: 13px;
  margin-bottom: 8px;

  * {
    vertical-align: middle;
  }

  img {
    margin-right: 4px;
  }

  b {
    margin-right: 4px;
    color: $paidy-pink;
  }
}

.separator {
  width: 100%;
  height: 0;
  border: 0;
  border-top: 1px solid $paidy-off-white;
  margin: 0 0 16px 0;
}

.controls {
  border-top: 1px solid #e1e1e1;
  padding: 16px 24px 24px 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}

.info-section-message {
  padding: 16px;
  padding-left: 8px;
  border-radius: 16px;
  background: #FAFAFA;
  color: #4F4F4F;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  span {
    font-size: 12px;
    margin-left: 16px;
    color: #4f4f4f;
  }
}
