@import '../../styles/base';

.container {
  width: 100%;
  background-color: #FAFAFA;
  border-radius: 12px;
  margin-bottom: 16px;
}

.left-header {
  padding-top: 17px;
  padding-left: 17px;
  padding-right: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #E0E0E0;
  padding-right: 16px;
}

.title-header {
  display: flex;
  flex-grow: 1;
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
}

.right-header {
  display: flex;
  color: $paidy-pink;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  & > p > a {
    text-decoration: none;
  }
}

.content {
  display: flex;
  flex-direction: row;
}

.content-body {
  color: #8A8A8A;
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}