@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.title {
  font-size: 20px;
  margin-bottom: 24px;
}

.name {
  margin-bottom: 24px;

  fieldset {
    vertical-align: top;
    width: calc(50% - 8px);

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }

  fieldset + fieldset {
    margin-top: 0;
  }
}

.controls {
  padding: 0 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  margin-top: 16px;

  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
