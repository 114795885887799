@import './styles/normalize';
@import './styles/base';

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html,
body {
  height: 100%;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  background-color: rgba(#000, 0.25);
  color: $paidy-black;
  font-family: $font-family;
  font-size: 16px;
  line-height: 1.5;
  font-weight: map-get($font-weight, light);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -ms-text-size-adjust: 100%;
  // -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
  touch-action: manipulation;
  // firefox scrollbar hack
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

:global(#root) {
  height: 100%;
  position: relative;
}

select::-ms-expand {
  display: none;
}

a {
  color: $paidy-pink;
  text-decoration: underline;

  &[disabled] {
    color: $paidy-ash;
    cursor: not-allowed;
    pointer-events: none;
  }
}

b {
  font-weight: map-get($font-weight, semi-bold);
}

@import './styles/form';
