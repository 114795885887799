@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 24px;
}

.illustrations {
  width: 100%;
  max-width: 312px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 64px 1fr 64px;
  align-items: center;
  row-gap: 24px;

  img {
    width: 64px;
    height: 64px;
  }
}

.description {
  font-size: 13px;
  line-height: 1rem;
  margin: 24px 0;
}

.secondary-text {
  color: $paidy-ash;
}

.controls {
  border-top: 1px solid #e1e1e1;
  padding: 16px 24px 0 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
