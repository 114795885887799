@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px;
  background: url("../../assets/bg-pending.jpg") no-repeat center;
  background-size: cover;
  color: $paidy-charcoal;
}

.content-wrapper {
  flex-grow: 1;
  flex-basis: 0;
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;

  span {
    font-size: 13px;
  }
}

.mobile-wrapper {
  & > * {
    display: block;
    margin: auto;
    
    &:not(:last-child):not(b) {
      margin-bottom: 24px;
    }
  }
}

.desktop-wrapper {
  display: none;
}

.title {
  text-align: center;
  color: $paidy-black;
  margin-bottom: 16px;

  @media only screen and (min-width: $responsive-breaking-point) {
    font-size: 20px;
    color: $paidy-charcoal;
  }
}

.desktop-title {
  text-align: center;
  margin-bottom: 14px;
  font-size: 20px;
}

.instructions-desktop {
  display: none;
}

@media only screen and (min-width: $responsive-breaking-point) {
  .mobile-wrapper {
    display: none;
  }

  .desktop-wrapper {
    display: inherit;
    margin: 0 auto;

    & > b {
      margin-bottom: 14px;
    }
  
    & > * {
      display: block;
      margin: auto;
      
      &:not(:last-child):not(b) {
        margin-bottom: 24px;
      }
    }
  }

  .instructions-mobile {
    display: none;
  }

  .instructions-desktop {
    display: inherit;
  }

}

.desktop-qr-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-container {
  display: flex;
  margin-bottom: 14px;
  & > img {
    margin-right: 13px;
  }
  b {
    color: $paidy-black;
    font-size: 16px;
  }
  span {
    color: $paidy-black;
    font-size: 13px;
    & > b {
      font-size: 13px;
    }
  }
  p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 700;
  }
}

.install-mobile-app {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid $paidy-ash;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  justify-items: center;

  > img {
    display: none;
    width: 120px;
    height: 120px;
  }

  @media only screen and (min-width: $responsive-breaking-point) {
    a {
      display: none;
    }

    > img {
      display: initial;
    }
  }
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  border: 1px solid #E5E5E5;
  margin-bottom: 14px !important;
}

.button {
  width: 346px;
}

.cic-hours-message {
  padding: 16px;
  border-radius: 16px;
  background: $paidy-gray-super-light;
  color: $paidy-gray-dark;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 16px;
  align-items: center;
  margin-top: 24px;
}
