@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  margin: 0 0 24px 0;
  color: $paidy-charcoal;
}

.title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 24px;
}

.illustrations {
  display: grid;
  grid-template-columns: 64px 1fr;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;

  img {
    width: 64px;
    height: 64px;
  }
}

.controls {
  padding: 0 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  font-weight: 600;
  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
