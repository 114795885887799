@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.title {
  font-size: 20px;
  margin-bottom: 24px;
}

.details {
  padding: 24px 0;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.controls {
  padding: 16px 24px 0 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
