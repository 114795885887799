@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;

  p:first-of-type {
    margin-top: 0;
  }
}

.title {
  font-size: 20px;
  margin-bottom: 24px;
  line-height: 24px;
}

.controls {
  padding: 0 24px;
  text-align: center;
  color: $paidy-charcoal;
  height: 80px;
}

.controls-space {
  padding: 0 8px;
  text-align: center;
  color: $paidy-charcoal;
}

.controls-wrapper {
  height: auto;
}

.controls-no-footer {
  padding-bottom: 24px;
}

.button {
  margin-top: 16px;

  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}

.button-space {
  margin-bottom: 24px;
  margin-top: 0;
}
.button-space-dark {
  margin-bottom: 24px;
  margin-top: 16px;
}

.button-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  height: 48px;
  border-radius: 25px;
  color: #fff;
  border: 0;
  user-select: none;
  text-align: center;
  width: 100%;
  padding: 0;
  @extend %primary-gradient;

  &:disabled {
    pointer-events: none;
    background: #e1e1e1;
  }
}

.link-button {
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  height: 48px;
  border-radius: 25px;
  color: #fff;
  border: 0;
  user-select: none;
  text-align: center;
  width: 100%;
  padding: 0;
  @extend %primary-gradient;

  &:disabled {
    pointer-events: none;
    background: #e1e1e1;
  }
}

.bulleted-item {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  img {
    @include rem(height, 24px);
    @include rem(width, 24px);
    @include rem(margin-right, 8px);
  }
  span {
    @include rem(font-size, $base-font-size);
    @include rem(line-height, 22px);
    @include rem(font-weight, map-get($font-weight, light));
    color: $paidy-charcoal;
  }
  &:nth-of-type(2) {
    @include rem(margin-bottom, 16px);
  }
}

.notification-text {
  color: $paidy-ash;
  @include rem(margin-top, 16px);
  p {
    margin: 0;
    @include rem(font-size, 12px);
    @include rem(line-height, 18px);
    @include rem(font-weight, map-get($font-weight, light));
  }
}

.with-icon{
  display: flex;
  align-items: center;
}

.with-icon img{
  margin-right: 8px;
}

.rejected-image{
  max-width: 185px;
  margin: 0 auto;
}

.kyc-required-content-wrapper {
  text-align: center;
}

.kyc-required-info-icon {
  margin-top: 16px;
  margin-bottom: 16px;
}

.kyc-required-title {
  font-size: 16px;
  color: $paidy-black;
  margin-bottom: 16px;
}

.kyc-required-subtitle {
  font-size: 13px;
  color: $paidy-charcoal;
  margin-bottom: 24px;
}

.kyc-required-install-mobile-app {
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid $paidy-ash;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  justify-items: center;
}

.kyc-required-instructions {
  margin-top: 24px;
  text-align: left;
}


.scrollable-content-desktop {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 40px 32px 0 32px;
  color: $paidy-charcoal;

  p:first-of-type {
    margin-top: 0;
  }
}

.description {
  @include rem(margin-top, 0);
  @include rem(margin-bottom, 8px);
  @include rem(line-height, 24px);
}
.description-desktop {
  @include rem(margin-top, 0);
  @include rem(margin-bottom, 0);
  @include rem(line-height, 24px);
}

.description-last {
  @include rem(margin-top, 0);
  @include rem(margin-bottom, 16px);
  @include rem(line-height, 24px);
}

.space-top-16 {
  margin-top: 16px;
}

.download-container {
  background-color: $paidy-header-color;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @include rem(min-height, 240px);
  @include rem(height, 240px);
  @include rem(margin-top, 8px);
  @include rem(margin-bottom, 8px);
  @include rem(border-radius, 16px);
  .download-image {
    @include rem(height, 240px);
    @include rem(width, 240px);
    display: inline-block;
  }
  .download-qr-container {
    @include rem(height, 240px);
    @include rem(width, 240px);
    @include rem(padding, 24px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .qr-container {
      @include rem(height, 88px);
      @include rem(width, 88px);
      @include rem(min-width, 88px);
      @include rem(min-height, 88px);
      @include rem(border-radius, 8px);
      @include rem(margin-bottom, 8px);
      background-color: $white;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .qr-title {
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: $paidy-charcoal;
    }
    .qr-description {
      font-size: 8px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
      color: $paidy-black;
      @include rem(margin-top, 8px);
    }
    .store-container {
      display: flex;
      flex-direction: row;
      @include rem(height, 24px);
      .app-store-download {
        @include rem(margin-right, 8px);
      }
      .google-play-download {

      }
    }
  }
  .download-qr-container-no-image {
    flex: 1;
  }
}

.download-container-remove-bottom-space {
  margin-bottom: 0;
}

.bottom-image {
  border-radius: 16px;
  //height: 100%;
  width: 100%;
  object-fit: contain;
}

.button-dark {
  background: #404042;
}

.sorry-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.space-vertical-24 {
  margin: 24px 0;
}

.description-desktop-bottom-24 {
  margin-bottom: 24px;
}

.description-desktop-retriable {
  margin-top: 16px;
}

.bulleted-item-last {
  margin-top: 8px;
}

.bottom-space-16 {
  margin-bottom: 16px;
}

.bottom-space-24 {
  margin-bottom: 24px;
}

.text-align-center {
  text-align: center;
}

.ul{
  list-style-type: disc;
  margin: 0;
  margin-bottom: 4px;
  padding: 0 0 0 25px;
  li{
    margin: 0 0 12px 0;
    padding-left: 8px;
    &::marker {
      color: $paidy-charcoal;
    }
  }
}
