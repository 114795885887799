@import '../styles/base';

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 16px;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;

  input[type='checkbox'] {
    appearance: none;
    // visibility: hidden; // needed for IE 11
    opacity: 0;
    // hack for iOS
    border: 0;
    width: 0;
    position: absolute;
    background: transparent;
    // end of hack

    &:checked,
    &:not(:checked) {
      + * + .icon {
        display: block;
        left: 0;
        width: 16px;
        height: 16px;
        @extend %vertical-center;
      }
    }

    &:not(:checked) {
      + * + .icon {
        border: 1px solid #404042;
        background: #fff;
        border-radius: 2px;
      }
    }

    &:checked {
      /* use general sibling selector for iOS8 */
      ~ .icon {
        background: url('icon-checked.svg') center no-repeat;
        background-size: cover;
      }
    }
  }
}
