@import '../../styles/base';

.details {
  $_double-padding-rem: $app-mobile-padding / $base-font-size * 2;

  position: relative;
  margin: 0 auto;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    background: url('dotted-line.svg') left center repeat-x;
    @include rem(
      (
        height: 2px,
      )
    );
  }

  .row {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      @include rem(margin-bottom, 12px);
    }
  }

  .left {
    font-weight: map-get($font-weight, light);
    line-height: 1;
    @include rem(font-size, 16px);
  }

  .right {
    font-weight: map-get($font-weight, semi-bold);
    line-height: 1;
    @include rem(font-size, 16px);
  }
}

.confirm-title {
  text-align: center;
  @include rem(padding, 0 16px 16px 16px);
}

.btn-apply {
  @include rem(margin-top, 27px);
}

.checkbox {
  position: relative;
  display: block;

  input[type='checkbox'] {
    appearance: none;
    @include rem(margin-left, 22px);

    &:not(:checked)::after {
      display: block;
      content: '';
      right: 0;
      @include rem(
        (
          width: 30px,
          height: 30px,
          border: 1px solid $paidy-gray-dark,
          border-radius: 8px,
        )
      );
      @extend %vertical-center;
    }

    &:checked::after {
      display: block;
      content: '';
      right: 0;
      background: url('icon-checked.svg') center no-repeat;
      background-size: cover;
      @include rem(
        (
          width: 30px,
          height: 30px,
        )
      );
      @extend %vertical-center;
    }
  }
}

.with-padding-top {
  padding-top: 24px;
}
