@import '../../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  margin: 0 0 24px 0;
  color: $paidy-charcoal;

  input[type="email"],
  input[type="tel"] {
    font-size: 12px;
  }

  label {
    font-size: 10px;
    font-weight: 600;
  }
}

.controls {
  padding: 0 24px;
  text-align: center;
  color: $paidy-charcoal;
  color: $paidy-charcoal;
}

.toc {
  font-size: 13px;
}

.button {
  margin-top: 16px;

  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
