@import './base';

button {
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  height: 48px;
  border-radius: 25px;
  color: #fff;
  border: 0;
  user-select: none;
  text-align: center;
  width: 100%;
  padding: 0;
  @extend %primary-gradient;

  &:disabled {
    pointer-events: none;
    background: #e1e1e1;
  }
}

:global(fieldset) {
  border: 0;
  padding: 0;
  margin: 0;
  display: inline-block;

  & + & {
    margin-top: 24px;
  }

  label {
    font-size: 13px;
    font-weight: map-get($font-weight, semi-bold);
    line-height: 1rem;
  }

  input,
  select {
    width: 100%;
  }

  :global(.error) {
    font-size: 13px;
    color: $paidy-red;
    margin-top: 8px;
  }
}

input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='date'],
select {
  appearance: none;
  padding: 8px 0;
  color: inherit;
  font-family: $font-family;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;

  border: 0;
  -webkit-border-radius: 0;
  border-bottom: 1px solid $paidy-gray-light-bh;

  &:focus {
    outline: none;
    border-bottom-color: $paidy-charcoal;
  }

  &::placeholder {
    color: $paidy-gray-medium;
  }
}

:global(.select) {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 100%;

  select {
    cursor: inherit;
    padding-right: 24px;
    background: transparent;
  }

  // placeholder
  select[data-empty='true'] {
    color: $paidy-gray-medium;
  }

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    width: 16px;
    height: 16px;
    background: url('../assets/icon-chevron-down.svg') center no-repeat;
    background-size: cover;
  }
}

:global(.datepicker) {
  :global(.select) {
    width: calc(33.33% - 8px);
    background: transparent;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 6px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      margin-left: 6px;
    }
  }
}

button:focus {
  outline: none;
}
