@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.fixed-height-short {
  min-height: 44px;
  max-height: 44px;
  padding-top: 8px !important;
}
.fixed-height-medium {
  min-height: 70px;
  max-height: 70px;
  padding-top: 8px !important;
}
.fixed-height {
  min-height: 80px;
  max-height: 80px;
}
.fixed-height-lower {
  min-height: 90px;
  max-height: 90px;
}

.title {
  font-size: 20px;
  margin-bottom: 24px;
}

.illustrations {
  width: 100%;
  max-width: 312px;
  margin: auto;
  display: grid;
  grid-template-columns: 64px 1fr 64px;
  align-items: center;
  row-gap: 24px;

  img {
    width: 64px;
    height: 64px;
  }
}

.description {
  font-size: 13px;
  line-height: 1rem;
  margin: 24px 0;
}

.secondary-text {
  color: $paidy-ash;
}

.notification-text {
  font-size: 13px;
  margin: 24px 0;
  color: $paidy-ash;

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.controls {
  border-top: 1px solid #e1e1e1;
  padding: 16px 24px 0 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  font-weight: 600;
  max-width: 312px;
}

.backBtn {
  width: 100%;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 8px;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #e30e80;
    cursor: pointer;
  }
}
