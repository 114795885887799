@import '../../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 14px;
}

.inputs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  input[type="tel"] {
    font-size: 2rem;
    border-radius: 4px;
    border-bottom-width: 0;

    &:focus, &.error {
      border-radius: 4px 4px 0 0;
      border-bottom-width: 2px;
    }

    &.error {
      border-bottom-color: $paidy-red;
    }
  }
}

.input {
  max-width: 56px;
  height: 64px;
  font-weight: map-get($font-weight, semi-bold);
  text-align: center;
  background: $paidy-off-white;

  & + & {
    margin-left: 16px;
  }
}

.error-message {
  margin-top: 16px;
  text-align: center;
  font-size: 13px;
  color: $paidy-red;
}

.voice-prompt {
  margin-top: 24px;
  text-align: center;
  font-size: 12px;

  &.fade-in {
    will-change: opacity, transform;
    opacity: 0;
    transform: translateY(6rem);
    animation: fade-in-animation .5s 5s ease-in 1 forwards;
  }
}

.voice-prompt-instructions {
  margin-top: 48px;
  font-size: 13px;
  max-width: 272px;
  display: flex;
  flex-direction: column;
  align-self: center;

  span {
    text-align: center;

    &:first-child {
      padding: 4px 0;
      border-radius: 2px;
      border: 1px solid $paidy-gray-super-light;
      background: $paidy-gray-lightest;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

@keyframes fade-in-animation {
  0% {
    transform: translateY(6rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.auth-info {
  padding: 16px;
  border-radius: 8px;
  background-color: #fcfcfc;
  margin-top: 48px;
  display: flex;
  align-items: center;
  font-size: 13px;
  // Makes sure .voice-prompt won't render on top of it during the fade-in animation
  z-index: 2;

  hr {
    border: 0;
    border-top: 1px solid $paidy-off-white;
    margin: 8px 0;
  }

  span {
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div:first-child {
    flex: 1 0 0;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.controls {
  padding: 0 24px;
  text-align: center;
  color: $paidy-charcoal;
}

.button {
  margin-top: 16px;

  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
