@import '../../styles/base';

.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;

  @media only screen and (min-width: $responsive-breaking-point) {
    max-height: 744px;
    max-width: 544px;
    border-radius: 12px;
  }
}

.main-app-content-extend-height {
  @media only screen and (min-width: $responsive-breaking-point) {
    max-height: 844px;
  }
}

.main-app-content-extend-height-retriable {
  @media only screen and (min-width: $responsive-breaking-point) {
    max-height: 792px;
  }
}
