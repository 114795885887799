@import '../../styles/base';

.container {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global(.basic).content {
  padding: 32px;
}

:global(.form).content {
  @include rem(padding-bottom, 0);
}

:global(.no-padding-bottom).content {
  @include rem(padding-top, $app-desktop-padding);
  @include rem(padding-right, $app-desktop-padding);
  @include rem(padding-left, $app-desktop-padding);
}

:global(.full-width).content {
  @include rem(padding-top, $app-desktop-padding-top);
}

:global(.full-height).content {
  @include rem(padding-right, $app-desktop-padding);
  @include rem(padding-left, $app-desktop-padding);
}

.top-shadow,
.bottom-shadow {
  position: absolute;
  left: 0;
  right: 0;
  @include rem(height, 5px);
}

.top-shadow {
  top: 0;
  background: linear-gradient(to bottom, rgba(#fff, 1) 0%, rgba(#fff, 0.2) 100%);
}

.bottom-shadow {
  bottom: 0;
  background: linear-gradient(to top, rgba(#fff, 1) 0%, rgba(#fff, 0.2) 100%);
}

/* custom scrollbar */
.tracker {
  @include rem(
    (
      border-radius: 6px,
      width: 6px !important,
      bottom: 4px,
      top: 4px,
    )
  );

  &-vertical {
    @include rem(right, 4px);
  }
}

.thumb {
  position: relative;
  opacity: 0.4;
  background: $paidy-gray-medium;
  @include rem(border-radius, 4px);

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    opacity: 0;
    @extend %fullscreen;
  }
}

@media screen and (max-width: $responsive-breaking-point) {
  .tracker {
    @include rem(border-radius, 6px);
    @include rem(width, 4px !important);

    &-vertical {
      @include rem(right, 4px);
    }
  }

  .thumb {
    &::after {
      margin-right: -4px;
      @include rem(margin-top, -$app-mobile-padding / 2);
      @include rem(margin-bottom, -$app-mobile-padding / 2);
      @include rem(margin-left, -$app-mobile-padding / 2);
    }
  }

  :global(.basic).content {
    @include rem(padding-top, $app-mobile-padding);
    @include rem(padding-top, $app-desktop-padding-top);
    @include rem(padding-bottom, 0);
  }

  :global(.no-padding-bottom).content {
    @include rem(padding-top, $app-mobile-padding);
    @include rem(padding-right, $app-mobile-padding);
    @include rem(padding-left, $app-mobile-padding);
  }

  :global(.full-width).content {
    @include rem(padding-top, $app-mobile-padding);
  }

  :global(.full-height).content {
    @include rem(padding-right, $app-mobile-padding);
    @include rem(padding-left, $app-mobile-padding);
  }
}
