@import '../../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.title {
  font-size: 16px;
  margin-bottom: 24px;
}

.faq {
  margin: 0 -24px;
  border-top: 1px solid $paidy-off-white;

  &:last-of-type {
    border-bottom: 1px solid $paidy-off-white;
  }

  .question {
    padding: 24px;
    height: auto;
    background: none;
    color: $paidy-charcoal;
    border-radius: 0;

    display: flex;
    align-items: center;

    span {
      text-align: left;
      flex-grow: 1;
      font-size: 10px;
    }

    .chevron {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-left: 24px;
      transition: transform 0.2s ease;
      will-change: transform;

      &.inverse {
        transform: rotate(180deg);
      }
    }
  }

  .answer {
    font-size: 13px;
    padding: 24px;
    margin: 0;
    background: $paidy-gray-lightest;
    border-top: 1px solid $paidy-off-white;

    &.hidden {
      display: none;
    }
  }
}

.show-all {
  margin: 24px 0;
  font-size: 12px;
}
