@import '../../styles/base';

.main-container {
  border-bottom: 1px solid $paidy-off-white;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;

    .title {
      font-weight: map-get($font-weight, semi-bold);
    }

    .left-container {
      text-align: right;

      .description {
        display: block;
      }
    }
  }

  .explanation {
    text-align: right;
    display: block;
    color: $paidy-neutral-gray;
    margin-top: 12px;
    font-size: 13px;
    span{
      display: inline-block;
      text-align: left;
    }
    ul{
      text-align: left;
      margin: 0;
      padding-left: 30px;
      li{
        padding-left: 7px;
        margin-top: 10px;
      }
    }
  }
}

.padding {
  padding: 20px 0px;
}

.padding-with-explanation {
  padding: 16px 0px;
}

.no-bottom-border {
  border-bottom: 0px;
}