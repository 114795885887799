%full {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

%text-align-center {
  text-align: center;
}

%text-align-right {
  text-align: right;
}

%dotted-block {
  display: block;
  position: relative;

  &::after {
    top: auto;
    left: 0;
    bottom: 0;
  }
}

%dotted-bottom {
  display: block;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: url('dotted-line.svg') left center repeat-x;
    background-size: auto 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    // TODO: refine the dotted line background, currently the spacing is too big so most likely won't perfectly fit in the whole width (last dot is missing)
    // transform: scaleX(1.058);
  }
}

%primary-gradient {
  background: linear-gradient(90deg, #e5258c, #863bd4);
}

%successful-gradient {
  background: linear-gradient(135deg, #8bc34a 0%, #5da010 65%, #509600 100%);
}

%btn-primary {
  @include rem(margin, 24px auto);
}

%btn-shadow {
  box-shadow: 0 10px 8px -10px rgba(#000, 0.75);
}
