@import '../../styles/base';

$_checkmark-duration: 1s;
$_checkmark-delay: 0.3s;
$_text-animation-duration: 2s;

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes swing {
  from {
    transform: rotate(20deg);
  }

  to {
    transform: rotate(-90deg);
  }
}

@keyframes text-1 {
  0% {
    visibility: hidden;
  }

  25% {
    visibility: hidden;
  }

  50% {
    visibility: visible;
  }

  75% {
    visibility: visible;
  }

  100% {
    visibility: visible;
  }
}

@keyframes text-2 {
  0% {
    visibility: hidden;
  }

  25% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  75% {
    visibility: visible;
  }

  100% {
    visibility: visible;
  }
}

@keyframes text-3 {
  0% {
    visibility: hidden;
  }

  25% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  75% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.spin {
  animation: rotating 2.5s linear infinite;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.text {
  font-size: 0;
  @extend %center;

  span {
    display: inline-block;
    font-weight: bold;
    color: $blue-dark;
    @include rem(font-size, 20px);
  }
}

.checkmark-wrapper {
  position: relative;
  display: block;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  z-index: 2;
  @include rem(
    (
      width: 108px,
      height: 108px,
      margin: 1px auto,
    )
  );
}

.checkmark {
  @include rem(
    (
      width: 48px,
      height: 45px,
    )
  );
  @extend %center;
}

.mask {
  position: absolute;
  background: #fff;
  transform-origin: 0 0;
  will-change: transform;
  z-index: 1;
  animation: swing $_checkmark-duration ease-in-out $_checkmark-delay forwards 1;
  @include rem(
    (
      transform: rotate(20deg),
      left: 40px,
      width: 50px,
      height: 80px,
    )
  );
}

.big-loading-animation {
  display: block;
  width: 100%;
  background: #fff url('bg-big-loading-animated.svg') center no-repeat;
  background-size: cover;
  @include rem(
    (
      height: 160px,
      margin-top: 4px,
    )
  );
}

.big-loading-text {
  display: block;
  font-weight: map-get($font-weight, bold);
  line-height: 1;
  margin-top: 50px;
  text-align: center;
  @include rem(font-size, 20px);
}
