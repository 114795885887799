@import '../../styles/base';

.content {
  @include rem(padding, $app-mobile-padding 0);
  font-size: 0;
  @extend %dotted-block;

  .text {
    text-align: center;
  }

  .desc {
    @include rem(font-weight, map-get($font-weight, normal));
    @include rem(font-size, 13px);
    line-height: 1;
    display: inline-block;
    color: $paidy-gray;
    @include rem(width, 262px);
    text-align: left;

    &:last-child {
      @include rem(margin-top, 8px);
    }
  }
}

.btn-multipay {
  display: inline-block;
  font-weight: 400;
  @include rem(margin-bottom, 10px);
  @include rem(font-size, $base-font-size);
}

.icon {
  @include rem(margin, 0 auto 16px);
  display: block;
  width: 56px;
  height: 56px;
  background: url('icon-big.svg') center no-repeat;
  background-size: cover;
}

@media screen and (min-height: 568px) {
  .content {
    @include rem(padding, 24px 0);
  }

  .icon {
    width: 80px;
    height: 80px;
    @include rem(margin, 0 auto 24px);
  }
}
