@import '../../../styles/base';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    background: $paidy-header-color;
    padding: 24px;
    height: 136px; // Header should not change its height if the content changes
  }

  nav {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    justify-items: center;
    align-items: center;
    height: 24px;
    margin-bottom: 16px;

    button {
      display: inline-block;
      border: none;
      width: 24px;
      height: 24px;
      appearance: none;
      background: none;
      padding: 0;
      margin: 0;
      line-height: 0;
      border-radius: 0;
    }
  }

  main {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
  }

  footer {
    padding: 16px 24px;
    text-align: center;

    a {
      font-size: 13px;
      text-decoration: underline;
      color: $paidy-pink;
    }
  }
}

.introLogoContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;

  img {
    height: 48px;
  }
}

.shopInfoContainer {
  display: grid;
  grid-template-columns: 1fr 64px;
  grid-template-areas:
    'merchantName merchantLogo'
    'amount  merchantLogo';
  margin-top: 18px;

  .merchantName {
    font-size: 12px;
    grid-area: merchantName;
    margin-right: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .merchantLogo {
    grid-area: merchantLogo;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    grid-column-start: 2;
    grid-row-end: span 2;
    background-color: #ffffff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .amount {
    grid-area: amount;
    margin-right: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .amountNumber {
      font-size: 21px;
      margin-right: 4px;
    }
  }
}
