@import '../../styles/base';

.container {
  @include rem(padding-top, 16px);
  position: relative;
}

.expand-button {
  position: absolute;
  @include rem(height, 96px);
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 43.23%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include rem(padding-bottom, 16px);
  div {
    display: flex;
    justify-content: center;
    @include rem(margin-bottom, 15px);
  }
  .expand-button-text {
    font-family: $font-family;
    font-style: normal;
    font-weight: 300;
    @include rem(font-size, 13px);
    @include rem(line-height, 16px);
    text-align: center;
    color: $paidy-link-pink;
  }
}
