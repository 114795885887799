@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  color: $paidy-charcoal;
}

.heading {
  background: $paidy-header-color;
  padding: 0px 24px 0px 24px;
  height: auto;
  b {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }

  @media only screen and (min-width: $responsive-breaking-point) {
    max-height: 120px;
  }
}

.content {
  display: none;
}

.content-mobile {
  position: relative;
  background: $paidy-header-color;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $paidy-charcoal;
}

.video-scroll-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.01;
}

.video-component {
  width: 100%;
  object-fit: contain;
}

.timer-img-container {
  margin: 0 auto;
}

.title {
  font-size: 20px;
  text-align: center;
  margin: 12px 0px 12px 0px;
  line-height: 24px;
}

.body {
  font-size: 16px;
  text-align: center;
  margin: 12px 0px 12px 0px;
  line-height: 24px;
}

.info-section {
  padding: 16px;
  border-radius: 16px;
  background: $paidy-off-white;
  color: $paidy-black;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 16px;
  align-items: center;
  margin-top: 12px;
  span {
    font-size: 14px;
  }

}

.modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding-left: 35px;
  padding-right: 36px;
  padding-top: 214px;

  @media only screen and (min-width: $responsive-breaking-point) {
    max-height: 744px;
    max-width: 544px;
    border-radius: 12px;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 146px;
  }
}

.modal-content {
  width: 100%;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 32px 25px 32px 24px;
  height: 296px;
}

.modal-content-icon {
  margin: 24px 0px 24px 0px;
}

.modal-content-message {
  margin: 12px 0px 0px 0px;
  font-size: 16px;
  color: black;
}

@media only screen and (min-width: $responsive-breaking-point) {
  .content-mobile {
    display: none;
  }
  
  .content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: start;
    background: $paidy-header-color;
  }
}