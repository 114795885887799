@import '../../../styles/base';

.container {
  background-color: #fff;
  border-radius: 16px;
  padding: 10px 12px;
  font-size: 10px;
  font-weight: 400;
  position: absolute;
  left: 6px;
  right: 6px;
  box-shadow: 0 9.961px 23.544px 0 rgba(0, 0, 0, 0.25);
  max-width: 290px;

  will-change: opacity, top;
  opacity: 0;
  top: -240px;
  animation: sms-fade-in-out-animation 3.7s ease-in-out 1;

  .timestamp {
      text-align: right;
      color: #3F3F3F;
      font-size: 9px;
      margin: 0;
  }
}

.content {
  display: flex;

  .icon {
    img {
        width: 31px;
        height: 31px;
    }
  }

  .message {
    padding-left: 12px;
    p {
        font-weight: 400;
        margin: 0;
        color: #000;
        text-align: left;
    }
  }
}

// 0.4s fade-in, 3s staying on screen, 0.3s fade-out
@keyframes sms-fade-in-out-animation {
  0% {
    top: -240px;
    opacity: 0;
  }

  10.81% {
    top: -120px;
    opacity: 1;
  }

  91.89% {
    top: -120px;
    opacity: 1;
  }

  100% {
    top: -240px;
    opacity: 0;
  }
}
