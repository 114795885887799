@import '../../styles/base';

.scrollable-content {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 24px 24px 0 24px;
  margin: 0 0 24px 0;
  color: $paidy-charcoal;
  fieldset {
    position: relative;
    label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }
    input {
      -webkit-tap-highlight-color: transparent;
    }
    span.label {
      font-size: 13px;
      font-weight: map-get($font-weight, semi-bold);
      line-height: 1rem;
    }
    .error {
      color: $paidy-red;
      font-size: 14px;
      margin: 0px 0 12px 0;
    }
  }
}

.help-modal-wrapper {
  .help-modal-overlay {
    &.opened {
      top: 0;
      left: 0;
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  .help-modal {
    background-color: #e1e1e1;
    border-radius: 24px 24px 0 0;
    padding: 36px 24px;
    position: absolute;
    z-index: 2;
    width: 100%;
    max-width: 544px;
    bottom: -320px;
    transition: bottom 0.5s ease-in-out;
    &.opened {
      bottom: -15px;
    }
    .help-action {
      background-color: #fff;
      display: flex;
      height: 56px;
      min-width: 288px;
      padding-left: 16px;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      margin-bottom: 8px;
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
      svg {
        width: 40px;
        height: 56px;
      }
      a {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #1c1c1c;
      }
    }
  }
}

.success-email-update-modal-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  .success-email-update-modal {
    width: 305px;
    height: 305px;
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: calc(50% - 150px);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    svg {
      margin-top: 30px;
    }
    span {
      display: block;
      font-weight: 600;
    }
    .success-email-update-modal-text {
      margin-top: 42px;
    }
  }
}

.controls {
  padding: 0 24px;
  text-align: center;
  color: $paidy-charcoal;
  color: $paidy-charcoal;
}

.toc {
  font-size: 13px;
}

.generic-error {
  color: #db263e !important;
  font-size: 14px !important;
}

.button {
  margin-top: 16px;
  font-weight: 600;
  @media only screen and (min-width: $responsive-breaking-point) {
    max-width: 312px;
  }
}
