@import '../../styles/base';

.success-wrapper {
  flex: 1;
  display: grid;
  background: url('../../assets/bg-success.png') no-repeat center;
  background-size: cover;

  div {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    margin-bottom: 24px;
  }

  b {
    color: #ffffff;
    font-size: 28px;
  }
}
